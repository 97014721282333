#group-container-basic,
#group-container-advanced,
#group-container-layout,
#group-container-premium,
#group-container-data {
  display: grid;
  gap: 0.1rem;
}

.builder-group-button {
  width: 100%;
}