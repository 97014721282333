
body{
    background-color: rgb(240, 242, 245) !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  }


  .wrap_mobile_stepper .MuiButton-root{
    cursor: none;
  }


  .wrap_mobile_stepper .MuiButton-root:hover{
    background: none !important;
  }

.item-title-dashbord{
  background: rgb(28,51,52);
  background: linear-gradient(90deg, #023e8a 0%, rgba(255,255,255,0) 100%);
  color: white;
  padding: 12px;
  width: 100%;
}


.css-8f6v64{
  width: 82% !important;
}

.css-17x63fa {
  max-width: none !important;
  background: none;
}

.questlist{
    border-bottom: 1px solid #dbcbcb;
}

.title-dashbord{
  width: max-content;
  /*color: #023e8a;*/
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 50px;
  border-bottom: 1px solid #0096c7;

}


.btn-nutrifast{
 /* background-color: #023e8a!important;*/
  color: white !important;
  font-weight: bold !important;
  border: aliceblue !important;
  padding: 8px !important;
   }

.btn-nutrifast:hover{
    border: 1px solid #023e8a !important;
}


.css-1ps6pg7-MuiPaper-root{
      background-color: transparent !important;
      box-shadow: none !important;
    }
.css-1ex1afd-MuiTableCell-root{
border-bottom: none !important;
}

.css-1ygcj2i-MuiTableCell-root{
  border-bottom: none !important;
  color: white !important;
  padding: 20px !important;
}


.css-1q1u3t4-MuiTableRow-root{
  background-color: #023e8a !important;

}

.tablecelladd{
  padding-right: 15px;
  font-size: 38px !important;
}






.fab-container{
  position: relative;
  bottom: -15px;
  right: 0px;
  cursor: pointer;
  float: inline-end;
  
  }


  .iconbutton{
  width:50px;
  height:50px;
  border-radius: 100%;
  background: #FF4F79;
  box-shadow: 10px 10px 5px #aaaaaa;
    }


    .button-add{
    width:60px;
    height:60px;
   }


    .iconbutton span{
      display:flex;
      align-items:center;
      justify-content:center;
      height: 100%;
      color:white;
      }



  .fab-container >    span{
    position: absolute;
    right: 80px;
    width: max-content;
    top: 8px;
      }
      

.user-edit-role-field{
  width: 50%;
  /*background-color: red !important;*/
  margin: auto;
  text-align: initial;
}      

.item-user-show{
  background: #a9a9a93d !important;
}

.item-user-show:hover{
  background: #023e8a !important;
  color: white;
}

.item_field_addquest_dialog{
  margin-bottom: 20px !important;
}

.wrap_logos_footer{
 text-align: center;
 margin-top: 400px;
}

.wrap_logos_footer img{
  width: 9%;
  padding: 1%;
}


.text_footer{
  margin: 0 auto;
  width: 70%;
  margin-top: 50px;
}







/* CSS  DU STEP DANS LE QUESTIONNAIRE */


.step_container{
  margin-bottom: 30px;
  display: flow-root;
  color:  #023e8a;
  float: none;
  font-weight: bolder;
}

/*
.first_steps .first_step{
float: left;
margin-right: 30px;

}

.last_steps .last_step{
  float: right;
  margin-left: 30px;
  
  }
*/





  .tab_steps{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }




  .tab_steps .tab_step{
    float: left;
display: none;
width: max-content;

  }


  .tab_steps .css-j5w0w9-MuiStepConnector-root{

    visibility: hidden;
  }



  .tab_steps .css-j5w0w9-MuiStepConnector-root::nth-last-child(3){
    display: none !important;
  }





.tab_steps .tab_step:nth-child(-n + 5){

display: block !important;
/*margin-right: 30px;*/
  }


/*

  .tab_step:nth-child(3)::after{
    content: " .........  ";
    font-weight: lighter;
      }

*/


.tab_steps  .tab_step:nth-last-child(1){

    display: block !important;
  /*  margin-left: 30px;*/
}


.tab_steps .tab_step:nth-last-child(3) {
  display: block !important;
  /*margin-left: 30px;*/
}

/*

.tab_step:nth-last-child(2)::before {
content: " ........ ";
font-weight: lighter;
}

*/

.tab_steps .tab_step:nth-last-child(2){


  display: block !important;
  /*margin-left: 60%;*/
}



.tab_steps .circle-step-nutrifast{
  background-color: #0d6efd;
  padding: 5px;
  color: white;
  border-radius: 76%;

}




.tab_steps .css-z7uhs0-MuiStepConnector-line{
  width: 50px !important;
}


.tab_steps .css-j5w0w9-MuiStepConnector-root:nth-last-child(2){
  display: contents !important;
  visibility: visible !important;
}




.tab_steps .css-j5w0w9-MuiStepConnector-root:nth-child(-n + 5){
  visibility: visible !important;
  display: contents !important;
}


.tab_steps .css-j5w0w9-MuiStepConnector-root:nth-child(-n + 5){
  visibility: visible !important;
  display: contents !important;
}




.tab_steps .css-j5w0w9-MuiStepConnector-root::nth-last-child(1){
  visibility: visible !important;
  display: contents !important;
}


.tab_steps .css-j5w0w9-MuiStepConnector-root::nth-last-child(3){
  visibility: visible !important;
  display: contents !important;
}



.MuiStepIcon-text {
  display: none;
}




/*
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
  background-color: #aaaaaa;
}
  */

  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
    color:rgba(0, 0, 0, 0.38) !important;
  }


 /* 
 
 name="data[submit]
 .component-button! > button[name="data[submit]"] {
  background-color: #FF4F79;
 }
 */




 .formio-component-label-hidden:nth-child(2):has(button[name="data[submit]"]) {
  text-align: center;
  padding-top: 40px;

}

.formio-component-label-hidden:nth-child(2):has(button[name="data[suivant]"]) {
  text-align: center;
  padding-top: 40px;

}


.formio-component-label-hidden:nth-child(2):has(button[name="data[retour]"]) {
  text-align: center;
  padding-top: 40px;

}



.css-11syc77-MuiPaper-root-MuiMobileStepper-root{
  max-width: none !important;
  margin-bottom: 30px;
}


.css-1be5mm1-MuiLinearProgress-root-MuiMobileStepper-progress{
  height: 14px !important;
  width: 82% !important
}



/*

.has-feedback:has(>button[name="data[suivant]"]) {

  padding-top: 40px;
 
}



*/


.wrap_mobile_stepper > .css-11syc77-MuiPaper-root-MuiMobileStepper-root{
  background: none !important;
  padding: 0 !important;
}



.wrap_mobile_stepper{
      /*padding: 20px;*/
     text-align:center;
      font-size:23px;
       margin-bottom:50px;
       color:rgb(2, 62, 138);
}

[type="submit"]{
  margin-top: 20px !important;
}


#wrap_info{
flex-direction: column;
display: flex;
/*width: 80%;*/
}


.block_item_info{
  
  width: auto;
  height: auto;

  margin-bottom:20px;
  text-align: center;

  
}


.wrap_images img{
width: 8%;
padding: 20px;
margin-bottom: 50px;
}

.titre_info h1{
  color:rgb(2, 62, 138);
  margin-bottom: 50px;
}

.objectifs{
  width: 60%;
  margin:  0 auto;
  background-color: white;
  padding: 40px;
  margin-bottom: 30px;
}


.objectifs h2{
  color:rgb(2, 62, 138);
}

.participation{
  margin: 0 auto;
  /*background-color: #0096c7;*/
  display: flex;
  flex-direction: row;
  width: 60%;
  margin-bottom: 50px;
}


.colonne_participation {
  width: inherit;
  }


.colonne_participation:first-child{
margin-right: 5px;
}

.colonne_participation:last-child{
  margin-left: 5px;
  }


.colonne_participation h3{
  padding:  10px 0 10px 0;
  background-color: rgb(2, 62, 138);
  color: white;
  width:100%;
  margin-bottom: 10px;
}


.colonne_participation >  p{
text-align: left !important;
font-weight: bold;
width:100%;
padding: 10px;
}


.colonne_participation_modalites{
  padding: 3%;
  border: 1px solid  rgb(2, 62, 138);
  text-align: left;
  position: relative;
}


.colonne_participation_modalites h5{
  color:rgb(2, 62, 138);

}

.colonne_participation_modalites > p{
  font-weight: bold;

}


.colonne_participation ol{
  text-align: left;
}



.ol_li_first_span{
  color:rgb(2, 62, 138);
  font-weight: bold;
}

.ol_li_first{
margin-bottom: 10px;
}

.colonne_participation ol > li ul >li{

list-style-type:disclosure-closed;
}

.qui_coordonne{
  width: 60%;
  text-align: center;
  margin:  0 auto;
  background-color: white;
  padding: 20px;
  color:rgb(2, 62, 138);
  margin-top: 50px;
}

.partnaires {
  margin:  0 auto;
  padding: 10px;
width: 60%;
  }

.partnaires div{
  padding: 30px;
display: flex;
flex-direction: row;
}


.partnaires div img{
width: 100px;
  }

  .partnaires div p{
    text-align: left;
    margin-left: 20px;
    border-left: 1px solid gray;
    padding: 10px;
      }



      .contact{
        width: 60%;
        text-align: center;
        margin:  0 auto;
        background-color: white;
        padding: 10px;
        color:rgb(2, 62, 138);
        margin-top: 50px;
        margin-bottom: 40px;
      }


      .arraw_icon{
        color:rgb(2, 62, 138);
        position: absolute;
        top: 105px;
        right: -17px;
      }


      .logout-icon{
        position: absolute;
        left: 96%;
        top: 20px;
        cursor: pointer;
      }



.consentement-form{
  border-collapse: collapse;
  border: 1px solid #000000;
  margin: auto;
  width: 65%;
  text-align: center;
}


.wrap_content_consentement{
  width: 60%;
  margin: 0 auto;
}


  .consentement-confirmation{
  margin: auto;
  width: 60%;
  }

  .css-30wlou{
    margin-left: -271px !important;
    padding: 15px !important;
    width: 20% !important;
  }





      /*Smartphones (portrait) */
      @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .titre_info h1 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;

  }




  .block_item_info {
    width: 100%;
    padding: 16px;

  }


  .css-30wlou{
    margin-left: -271px !important;
    padding: 15px !important;
    width: 20% !important;
  }


  .participation{
    display: flex;
    flex-direction: column;
    padding: 0;

  }

  .colonne_participation {
    margin-top: 40px;
  }



  .logout-icon{
    left: 87%;

  }

  .colonne_participation:first-child {
    margin-right: 0px;
  }

  .colonne_participation:last-child {
    margin-left: 0px;
  }


  .arraw_icon {
    display: none;
  }

  .objectifs h2 {
    font-size: 18px;
  }


  .colonne_participation h3 {
    font-size: 18px;
  }

  .colonne_participation_modalites h5 {
    font-size: 18px;
  }

  .qui_coordonne h2{
    font-size: 18px;
  }

  .qui_coordonne{
    margin-top: 10px;
  }


  .partnaires div img {
    width: 100px;
    height: 60px;
  }

  .partnaires div {
    padding: 10px;

  }

  .contact{
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .contact h2{
    font-size: 18px;
  }

  .wrap_logos_footer img {
    width: 23%;
    padding: 3%;
  }

  .text_footer {
    margin: 0 auto;

    width: 90%;
    margin-top: 50px;
  }


  .Auth-form-container {
      max-width: 369px;
  }


  .consentement-form{

    width: 100%;
 
  }

  .wrap_content_consentement{
    width: 95%;

  }

  .consentement-confirmation{
    margin: auto;
    width: 95%;
  }




  .css-1lnxnvi .MuiTextField-root {
  width: 100% !important;
  margin: 0 0 8px 0 !important;
}


.MuiPaper-root{
  padding: 0 !important;
}

.wrap_mobile_stepper > .MuiMobileStepper-positionStatic > .MuiButtonBase-root{
display: none;
}


.css-1be5mm1-MuiLinearProgress-root-MuiMobileStepper-progress {
  height: 14px !important;
  width: 98% !important;
}


}




















/* Tablettes (portrait) */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    /* Styles pour cette Media Queries */
    .wrap_mobile_stepper > .MuiMobileStepper-positionStatic > .MuiButtonBase-root{
      display: none;
      }


      .css-1be5mm1-MuiLinearProgress-root-MuiMobileStepper-progress {
        height: 14px !important;
        width: 100% !important;
      }

      .logout-icon {
      left: 92%;

      }


    .participation {
    width: 98%;
      
      }
  .objectifs {
  width: 100%;
  padding: 20px;

}

    .qui_coordonne {
     width: 100%;

    }


    .partnaires {
    width: 100%;
  }

  .contact {
    width: 100%;

  }


  .consentement-form {
    width: 90%;
  }



  .wrap_content_consentement {
    width: 90%;
    margin: 0 auto;
  }

  .titre_info h1 {
    font-size: 28px;
  }

  .objectifs h2 {
    font-size: 22px;
  }

  .colonne_participation h3 {
    font-size: 22px;
  }

  .colonne_participation_modalites h5 {
    font-size: 18px;
  }

  .qui_coordonne h2{
    font-size: 22px;
  }

  .wrap_logos_footer img {
    width: 17%;
    padding: 2%;
  }


    }





    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
      /* Styles pour cette Media Queries */

      .participation{
        width: 90%;
      }
      }








/*Smartphones (landscape) */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
      /* Styles pour cette Media Queries */


      .objectifs{

        width: 100%;
      }


      .participation{
        width: 100%;
      }


      .qui_coordonne{
        width: 100%;
      }

      .partnaires{
        width: 100%;
      }


      .partnaires div img {
        width: 100px;
        height: 50px;
      }

      .contact{
        width: 100%;
      }


      .wrap_logos_footer img {
        width: 19%;
        padding: 2%;
      }


      .logout-icon {
    left: 92%;
    }

      }